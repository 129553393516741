<script setup lang="ts">
  import { ModalQuery, useModalView } from '../composable/useModalView'
  import BaseModal, {
    Props as BaseModalProps,
    Slots as BaseModalSlots,
  } from './BaseModal.vue'

  const props = defineProps<
    {
      query: ModalQuery
    } & Pick<
      BaseModalProps,
      'contentClass' | 'containerClass' | 'closeOnClickOutside' | 'widthMode'
    >
  >()

  defineSlots<BaseModalSlots>()

  const { close, isOpen } = useModalView(props.query)
</script>

<template>
  <BaseModal
    v-slot="slotProps"
    :close-on-click-outside
    :container-class
    :content-class
    :open="isOpen"
    :width-mode
    @close="close"
  >
    <slot v-bind="slotProps" />
  </BaseModal>
</template>
